

import HelloWorld from './components/HelloWorld.vue'

export default {
  name: 'LayoutDefault',

  components: {
    HelloWorld
  },

  data () {
    return {
      leftDrawerOpen: false,
      text:'',
      dense : true,
      sirine : 0

    }
  },
  methods: {

    async bunyiSirine() {
      try {
        const response = await fetch('https://server-firetap.konaweselatankab.go.id/api/v1/updateLocation/sirine'); // Ganti dengan URL server Anda
        const data = await response.json();
        this.sirine = data.data[0].bunyi;



        if (this.sirine == 0) {
          document.getElementById("xxx").pause(); 
        } else {
          document.getElementById("xxx").play(); 
        }

      } catch (error) {
        console.error('Error fetching sirine', error);
      }
    },

  },
  computed: {
      checkLogin() {
          if (this.$route.name === 'login') {
              return true;
          } else {
              return false;
          }
      }
  },
  created() {
    this.bunyiSirine();
    setInterval(this.bunyiSirine, 1000); // Fetch data every 1 seconds
  }
}
